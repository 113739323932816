import { deleteDataById, getData, getExcel, getPaginatedData, insertData, patchData } from './commonService';

export default class EmployeeSalaryService {

    #defaultUrl = '/api/EmployeeSalary';
    #defaultResourceName = 'Maaş';

    async getEmployeeSalariesExcel(projectId, year, month) {
        return await getExcel(this.#defaultUrl + "/Excel?projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getEmployeeSalariesForAccounting(projectId, year, month) {
        return await getPaginatedData(this.#defaultUrl + "/query?isApproved=true&projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getEmployeeSalariesForAccountingExcel(projectId, year, month) {
        return await getExcel(this.#defaultUrl + "/Excel?isApproved=true&projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getEmployeeSalaries(projectId, year, month) {
        return await getPaginatedData(this.#defaultUrl + "/query?projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getAllEmployeeSalaries(projectId, employeeId) {
        return await getPaginatedData(this.#defaultUrl + "/query?projectId=" + projectId + "&employeeId=" + employeeId, this.#defaultResourceName);
    }

    async getEmployeeSalaryReport(projectId, year, month) {
        return await getData(this.#defaultUrl + "/report?projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getEmployeeSalaryReportForAccounting(projectId, year, month) {
        return await getData(this.#defaultUrl + "/report?isApproved=true&projectId=" + projectId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async calculateEmployeeSalaries(projectId, year, month, managerUserId, calculateOnlyPartial) {
        const createData = {
            "projectId": projectId,
            "year": year,
            "month": month,
            "managerUserId": managerUserId,
            "isActive": true,
            "calculateOnlyPartial": calculateOnlyPartial
        }
        return await insertData(this.#defaultUrl + "/calculate", createData, this.#defaultResourceName);
    }

    async deleteEmployeeSalary(employeeSalaryId)
    {
        return await deleteDataById(this.#defaultUrl, employeeSalaryId, this.#defaultResourceName);
    }

    async updateOfficialPaymentStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isOfficialPaymentApproved", "value": newStatus}
        ];
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }

    async updateUnOfficialPaymentStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isUnOfficialPaymentApproved", "value": newStatus}
        ];
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }

    async updateApproveStatus(id, newStatus) {
        const patchDocument = [
            {"op": "replace", "path": "isApproved", "value": newStatus}
        ];
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }



    async getSalaryByProjectEmployeeId(month, year, projectEmployeeId){
        return await getData(this.#defaultUrl + "/query?projectEmployeeId=" + projectEmployeeId + "&year=" + year + "&month=" + month, this.#defaultResourceName);
    }

    async getEmployeeSalaryChanges(projectEmployeeId) {
        return await getData( "/api/ProjectEmployeeSalaryChange/query?projectEmployeeId=" + projectEmployeeId, this.#defaultResourceName);
    }
}