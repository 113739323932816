<template>



    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Maaş Ödeme</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/resmi_odeme.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>

            <div class="grid mt-2">
                <div class="col-12 md:col-6 lg:col-6">
                    <div class='text-900 font-bold text-xl'>Ödenecek: <span class='font-light ml-1'>{{ formatPrice(report.totalOfficialPrice)}} &#8378;</span></div>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <div class='text-900 font-bold text-xl'>Ödenen: <span class='font-light ml-1'>{{ formatPrice(report.totalPaidOfficialPrice)}} &#8378;</span></div>
                </div>
            </div>
                
            <div class='text-900 font-bold text-xl'>
                    Kalan Ödeme: <span class="text-red-600"> {{ formatPrice(report.totalOfficialPrice - report.totalPaidOfficialPrice)}}<span class='font-light ml-1'>&#8378;</span></span>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Diğer Ödeme</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/diger_odeme.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>

            <div class="grid mt-2">
                <div class="col-12 md:col-6 lg:col-6">
                    <div class='text-900 font-bold text-xl'>Ödenecek: <span class='font-light ml-1'>{{ formatPrice(report.totalUnOfficialPrice) }} &#8378;</span></div>
                </div>
                <div class="col-12 md:col-6 lg:col-6">
                    <div class='text-900 font-bold text-xl'>Ödenen: <span class='font-light ml-1'>{{ formatPrice(report.totalPaidUnOfficialPrice) }} &#8378;</span></div>
                </div>
            </div>
                
            <div class='text-900 font-bold text-xl'>
                    Kalan Ödeme: <span class="text-red-600"> {{ formatPrice(report.totalUnOfficialPrice - report.totalPaidUnOfficialPrice) }}<span class='font-light ml-1'>&#8378;</span></span>
            </div>


        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Toplam Ödeme</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/kasa.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
                
            <div class='text-900 font-bold text-4xl'>
                    {{ formatPrice(report.totalPayment)}}<span class='font-light ml-1'>&#8378;</span><br><br>
                </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Kümülatif Toplam</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/kumulatif.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
                
            <div class='text-900 font-bold text-4xl text-green-600'>
                {{ formatPrice(report.totalPaymentWithAllowance)}}<span class='font-light ml-1'>&#8378;</span><br><br>
                </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Avans Toplamı</span>
                <div class='flex align-items-center justify-content-center border-round' style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/avans.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    {{ formatPrice(report.totalAllowancePrice) }}<span class='font-light ml-1'>&#8378;</span><br><br>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Mesai Toplamları</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/mesai.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">  
                <div class='text-900 font-bold text-xl'>
                    {{ formatPrice(report.totalOvertimePrice) }}<span class='font-light ml-1'>&#8378;</span> - {{ formatPrice(report.totalOvertimeHours) }}<span class='font-light ml-1'>Saat</span><br><br>
                </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>          
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Ücretsiz İzin Toplamı</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/izin.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
               <div class='text-900 font-bold text-xl'>
                <span class="text-red-600"> - {{formatPrice(report.totalDayOffPrice)}}<span class='font-light ml-1'>&#8378;</span></span> - {{ report.totalDayOffCount }} <span class='font-light ml-1'> Gün</span><br><br>
               </div>
            </div>
        </div>
    </div>

    <div class='col-12 md:col-6 lg:col-3'>
        <div class='surface-card shadow-0 p-3 border-round' style='border:1px solid #dfe7ef'>
            <div class='flex justify-content-between mb-3'>
                <span class='block font-medium mb-3 text-xl'>Prim Toplamı</span>
                <div class='flex align-items-center justify-content-center border-round'
                     style='width:2.5rem;height:2.5rem'>
                    <i>
                        <img src='/layout/images/icon/prim.svg' height='54' class='mt-2 mr-2' />
                    </i>
                </div>
            </div>
            <div class="mt-2">
                <div class='text-900 font-bold text-xl'>
                    <span> {{formatPrice(report.totalBonusPrice)}}<span class='font-light ml-1'>&#8378;</span></span><br><br>
                </div>
            </div>
        </div>
    </div>


</template>

<script>
export default {
    name: 'SalaryReport',
    props: {
        report: {},
    },
    methods : {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
    }
};
</script>

<style scoped lang='scss'>
</style>